import React from "react";

import Layout from "../components/layout";
import CloudinaryUploadWidget from "../components/camera/CloudinaryUploadWidget";
import Button from "../components/buttons/button";
import Swal from 'sweetalert2'
import {Helmet} from "react-helmet";

import heroImage from "../images/home/hero-image.png";

function triggerSubmit()
{
    if (document.getElementById('phone-number').value === '' || document.getElementById('agent').value === '') {

        Swal.fire({
            title: 'Error',
            text: 'Please enter all information',
            icon: 'error',
            confirmButtonText: 'OK'
        })
    }else{
        Swal.fire({
            title: 'Success',
            text: 'Information Saved Successfully',
            icon: 'success',
            confirmButtonText: 'OK'
        })
    }

}

function OnboardPage() {

    return (
        <Layout>
{/*            <Helmet>
                <script src="https://widget.cloudinary.com/v2.0/global/all.js" />
            </Helmet>*/}

        <section className="md:p-0 text-left mt-28 md:mt-24">
        <div className="flex flex-col gap-5 md:flex-row px-6 md:max-w-7xl mx-auto">
          <div className="md:pr-2">
            <div>
              <h1 className="inline-block mb-4 text-5xl md:text-6xl font-bold text-black font-heading relative z-20">
              Instructor Information Capture
              </h1>
              <div className="">
                <p className="pb-14 text-black font-body">Instructions here on what to upload.</p>
              </div>
              <div className="grid grid-col md:grid-cols-2">
                <div>
                    <label
                        className="block mb-2 text-xs font-bold uppercase"
                        htmlFor="phone-number"
                        >Driver Phone Number
                    </label>
                    <input
                        className="mb-6 rounded-md border-gray-800 w-full md:w-5/6 text-sm"
                        id="phone-number"
                        placeholder="eg: 065 821 ..."
                        type="number"
                    />
                </div>

{/*                <label
                    className="block mb-2 text-xs font-bold uppercase"
                    htmlFor="driver-image"
                >
                    Driver Image
                </label>

                <div style={{display:"flex"}}>
                    <CloudinaryUploadWidget caller="driver-image" />
                </div>*/}
                <div>
                    <label
                        className="block mb-2 text-xs font-bold uppercase"
                        htmlFor="agent"
                    >Agent Reference
                    </label>
                    <input
                        className="mb-6 rounded-md border-gray-800 w-full md:w-5/6 text-sm"
                        id="agent"
                        placeholder="Referring Agent Name"
                        type="text"
                    />
                </div>
                <div>
                    <label
                        className="block mb-2 text-xs font-bold uppercase"
                        htmlFor="car-image"
                    >Car Image
                    </label>
                    <div>
    {/*                   <input
                            className="w-full mb-6 rounded-md border-gray-800"
                            id="phone-number"
                            placeholder="Car Image"
                            type="text"
                        />*/}
                        <CloudinaryUploadWidget caller="car-image" />
                        {/*<button onClick={() => showUploadWidget()}>Capture Picture</button>*/}
                    </div>
                </div>
                </div>
                <div className="mt-10">
                    <Button title="Save details" onClick={() => triggerSubmit()}>
                        Save Details
                    </Button>
                </div>
            </div>
          </div>
          <img
            alt="driving school illustration"
            src={heroImage}
            className="object-contain w-3/4 md:w-3/6 mx-auto"
          />
        </div>
        </section>

        </Layout>
    );
}

export default OnboardPage;
