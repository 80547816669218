import React, { Component } from "react";

import cameraIcon from "/src/icons/camera.svg";

class CloudinaryUploadWidget extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const cloudName = "dpc0hkzb0"; // replace with your own cloud name
        const uploadPreset = "nubee-app"; // replace with your own upload preset

        // Remove the comments from the code below to add
        // additional functionality.
        // Note that these are only a few examples, to see
        // the full list of possible parameters that you
        // can add see:
        //   https://cloudinary.com/documentation/upload_widget_reference
        console.log(this.props);

        var myWidget = window.cloudinary.createUploadWidget(
            {
                cloudName: cloudName,
                uploadPreset: uploadPreset,
                // cropping: true, //add a cropping step
                // showAdvancedOptions: true,  //add advanced options (public_id and tag)
                sources: ["camera"] // restrict the upload sources to URL and local files
                // multiple: false,  //restrict upload to a single file
                // folder: "user_images", //upload files to the specified folder
                // tags: ["users", "profile"], //add the given tags to the uploaded files
                // context: {alt: "user_uploaded"}, //add the given context data to the uploaded files
                // clientAllowedFormats: ["images"], //restrict uploading to image files only
                // maxImageFileSize: 2000000,  //restrict file size to less than 2MB
                // maxImageWidth: 2000, //Scales the image down to a width of 2000 pixels before uploading
                // theme: "purple", //change to a purple theme
            },
            (error, result) => {
                if (!error && result && result.event === "success") {
                    console.log("Done! Here is the image info: ", result.info);
                    //console.log(caller);
                   /* document
                        .getElementById("uploadedimage")
                        .setAttribute("src", result.info.secure_url);*/
                }
            }
        );
        document.getElementById("upload_widget").addEventListener(
            "click",
            function () {
                myWidget.open();
            },
            false
        );
    }

    render() {
        return (
            <button id="upload_widget" className="cloudinary-button font-body text-sm">
                <span className="flex gap-3 items-center">Capture Image <img className="w-6 inline-block" src={cameraIcon}/></span>    
            </button>
        );
    }
}

export default CloudinaryUploadWidget;
